import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, fetchLanguages } from 'wwtc-sdk';
import './login.css';
import './common/button.css';

function Login() {
    const history = useHistory();
    const [form, setForm] = useState({
        language: null,
        displayName: null,
    });
    const [info, setInfo] = useState({
        languages: [],
    });

    useEffect(() => {
        fetchLanguages().then(languages => {
            setInfo({
                languages,
            });
        });
    }, []);

    const handleLanguage = language => {
        setForm({ ...form, language: language });
    };

    const handleLogin = e => {
        e.preventDefault();

        if (validateLogin()) {
            const { displayName, language } = form;
            history.push(`/app`, {
                userName: displayName,
                language: language.value,
                gender: 'male',
            });
        } else {
            alert('Please fill inputs to continue');
        }
    };

    const validateLogin = () => {
        let valid = true;
        Object.keys(form).forEach(item => {
            if (!form[item] || form[item] === '') valid = false;
        });
        return valid;
    };

    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="div-tests-button"></div>
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img
                            src="https://sdkresources.s3.amazonaws.com/assets/logo.png"
                            alt="WWTC"
                        />
                        <div className="wrapper-title">
                            <p>WorldWide</p>
                            <h3 style={{ fontWeight: 'bold' }}>Tech Connections</h3>
                            <div className="line-title"></div>
                        </div>
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Welcome</h2>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    handleChange={handleLanguage}
                                    data={info.languages}
                                    value={form.language || ''}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className="Input"
                                    type="text"
                                    placeholder="Display name"
                                    value={form.displayName || ''}
                                    onChange={e =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className="Button Button-primary Button-block"
                            >
                                connect now
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
