const endpointTranslate = {
    method: 'POST',
    url:
        'https://core.worldwidetechconnections.com/services/ttt/:sourceLanguage/:targetLanguage?text=:text',
    valueResponse: 'translated_text',
};

const endpointTextToSpeech = {
    method: 'POST',
    url:
        'https://core.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage?text=:text',
};

const endpointSpeechToText = {
    method: 'POST',
    url: 'https://core.worldwidetechconnections.com/services/stt/:sourceLanguage/:sourceLanguage',
    valueResponse: 'source_text',
    isFormData: false,
};

export { endpointSpeechToText, endpointTextToSpeech, endpointTranslate };
