import React from 'react';
import { useHistory } from 'react-router-dom';
import { Subtitles, pubnub, TextToSpeech, SpeechToText, MainWrapper } from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
} from '../constants/connection';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const { userName, language, gender } = history.location.state;

    const roomName = window.room;
    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={roomName}
            userName={userName}
        >
            <Header pubnub={pubnub} />
            <WrapperComponents />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { pubnub, initName } = props;
    const history = useHistory();

    const handleLogout = () => {
        if (pubnub) {
            pubnub.unsubscribeAll();
            history.push('/');
        } else {
            history.push('/');
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img
                        src="https://sdkresources.s3.amazonaws.com/assets/LogoWWTC_Forms_Light-01.svg"
                        alt="WWTC"
                    />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span style={{ color: 'white' }}>The box 2.0</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from autologin
    const { initName, initLanguage, initGender, conferenceName } = props;

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(
                `https://core.worldwidetechconnections.com/services/tts/${sourceLanguage}/${sourceLanguage}?text=${text}`,
                {
                    method: 'POST',
                    redirect: 'follow',
                },
            );
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = base64 => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        <div className="wrapper-components">
            <Subtitles.SubtitlesUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                endpoint={endpointTranslate}
                provider="pubnub"
                speechRecognition={false}
                showInput={false}
                showButtonCloseComponent={false}
                ttsOption={true}
                channel={conferenceName}
                style={stylesWrapper.fullContainer}
            />
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={endpointSpeechToText}
            />
            <TextToSpeech.TextToSpeechUI
                initialVendor="readspeaker"
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={endpointTextToSpeech}
                fetchCustom={fetchTextToSpeechCustom}
            />
        </div>
    );
}

const stylesWrapper = {
    fullContainer: {
        width: 'calc(100% - 20px)',
        height: '100%',
        margin: '10px auto',
    },
};

export default Main;
